import { StreamChat } from "stream-chat";

const API_KEY = "jd8dbxatk7vd";
const client = StreamChat.getInstance(API_KEY);

async function OrderSupports() {
  const OrderSupport = client.channel("team", {
    name: "Order-Support",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await OrderSupport.create();
  };
  createChannel();
}

async function PneumaticTechs() {
  const PneumaticTech = client.channel("team", {
    name: "Pneumatic-Tech",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await PneumaticTech.create();
  };
  createChannel();
}

async function PneumaticSystems() {
  const PneumaticSystem = client.channel("team", {
    name: "Pneumatic-Systems",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await PneumaticSystem.create();
  };
  createChannel();
}

async function ElectricActuators() {
  const ElectricActuator = client.channel("team", {
    name: "Electric-Actuator",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await ElectricActuator.create();
  };
  createChannel();
}

async function ModularSolutions() {
  const ModularSolution = client.channel("team", {
    name: "Modular-Solutions",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await ModularSolution.create();
  };
  createChannel();
}

async function FloflexFittingAndTubings() {
  const FloflexFittingAndTubing = client.channel("team", {
    name: "Floflex-Fittings-&-Tubing",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await FloflexFittingAndTubing.create();
  };
  createChannel();
}

async function LightCurtainReers() {
  const LightCurtainReer = client.channel("team", {
    name: "Light-Curtain-Reer",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await LightCurtainReer.create();
  };
  createChannel();
}

async function VacuumSchmalzs() {
  const VacuumSchmalz = client.channel("team", {
    name: "Vacuum-Schmalz",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await VacuumSchmalz.create();
  };
  createChannel();
}

async function LinearRails() {
  const LinearRail = client.channel("team", {
    name: "Linear-Rail",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await LinearRail.create();
  };
  createChannel();
}

async function AluminumProfiles() {
  const AluminumProfile = client.channel("team", {
    name: "Aluminum-Profile",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await AluminumProfile.create();
  };
  createChannel();
}

async function SalesVisitRequests() {
  const SalesVisitRequest = client.channel("team", {
    name: "Sales-Visit-Request",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await SalesVisitRequest.create();
  };
  createChannel();
}

async function PneumaticProportionals() {
  const PneumaticProportional = client.channel("team", {
    name: "Pneumatic-Proportional",
    members: [
      client.user.id,
      "7ad00e9eea73dd13be64fcebfd9699e7", //Josh A
      "ab76cbf582c4e4d8a54aa4840c115acd", // Brandon T
    ],
  });
  const createChannel = async () => {
    await PneumaticProportional.create();
  };
  createChannel();
}

export {
  OrderSupports,
  PneumaticTechs,
  PneumaticSystems,
  ElectricActuators,
  ModularSolutions,
  FloflexFittingAndTubings,
  LightCurtainReers,
  VacuumSchmalzs,
  LinearRails,
  AluminumProfiles,
  SalesVisitRequests,
  PneumaticProportionals,
};
